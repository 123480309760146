<template>
    <div id="app">
        <Navbar :email="$store.state.auth.email" :has-role="hasRole" />
        <router-view />
    </div>
</template>

<script>
import Navbar from '@/shared/components/Navbar'
export default {
    components: {
        Navbar,
    },
    methods: {
        hasRole(role, mod = 'cat') {
            return this.$store.getters.hasRole(role, mod)
        },
    },
}
</script>

<style src="@/assets/style/index.scss" lang="scss"></style>

<style lang="scss">
#app {
    height: 100vh;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

h1,
h2,
h3 {
    font-family: 'Montserrat', sans-serif;
}
</style>
