<i18n>
{
  "nl-BE": {
    "building_catalog": "Catalogus van gebouwen",
    "catalog_tagline": "Ontdek gebouwkenmerken op basis van open data"
  },
  "en-BE": {
    "building_catalog": "Building catalog",
    "catalog_tagline": "Find out building characteristics based on open data"
  },
  "fr-BE": {
    "building_catalog": "Catalogue de bâtiments",
    "catalog_tagline": "Découvrez les caractéristiques des bâtiments basées sur les données ouvertes"
  }
}
</i18n>

<template>
    <div id="home">
        <spinner :active="spinnerActive" />
        <div v-show="animation.content_show" class="content p-4">
            <div class="logo">
                <img
                    src="@/shared/assets/icons/rockestate-logo-horizontal-dark.svg"
                    alt=""
                    class="logo"
                />
            </div>
            <h1>{{ $t('building_catalog') }}</h1>
            <div class="subtitle">
                {{ $t('catalog_tagline') }}
            </div>
            <div class="is-w100">
                <div class="columns is-mobile is-multiline pt-5 pb-1">
                    <div class="column is-full is-10-tablet is-12-mobile">
                        <auto-complete @selectedItem="selectedData($event)" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Spinner from '@/components/Spinner'
import AutoComplete from '@/shared/components/AutoComplete.vue'
import { mapMutations, mapActions } from 'vuex'

export default {
    name: 'Home',
    components: { Spinner, AutoComplete },
    data() {
        return {
            height: 0,
            spinnerActive: false,
            animation: {
                content_show: true,
            },
        }
    },
    computed: {},
    methods: {
        ...mapMutations(['UPDATE_ADDRESS', 'UPDATE_FEATURES']),
        selectedData(val) {
            this.UPDATE_ADDRESS({
                streetname: val.streetname,
                streetnumber: val.streetnumber,
                postalcode: val.postalcode,
                municipality: val.municipality,
            })
            this.UPDATE_FEATURES({ building_id: val.building_id })
            this.$router.push({
                name:
                    this.$config.PREFERRED_CATALOG_VIEW === 'report'
                        ? 'report'
                        : 'action',
                params: {
                    lang: this.$route.params.lang,
                    action:
                        this.$config.PREFERRED_CATALOG_VIEW === '3D' && val.level === '3'
                            ? '3d'
                            : '2d',
                },
                query: {
                    building_id: val.building_id,
                    streetname: val.streetname,
                    streetnumber: val.streetnumber,
                    postalcode: val.postalcode,
                    municipality: val.municipality,
                },
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.search-section {
    width: 100%;
}
#home {
    display: flex;
    justify-content: center;
    background: url('../shared/assets/icons/img-skyline.svg') no-repeat;
    background-position-y: bottom;
    background-position-x: center;
    height: 100%;
    transition: 0.3s all;
    overflow: auto;
}
.content {
    width: 100%;
    max-width: 600px;
    margin-top: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all 0.4s;
    position: relative;
}
</style>
