import Vue from 'vue'
import axios from './axios'

export async function fetchConfig() {
    const defaultConfig = {}
    Vue.prototype.$config = defaultConfig
    let data = null
    try {
        const response = await axios.get('/re_info/frontend_settings', {
            // query URL without using browser cache
            headers: {
                'Cache-Control': 'no-cache',
                Pragma: 'no-cache',
                Expires: '0',
            },
            params: {
                t: Date.now(),
            },
        })
        data = response.data
        localStorage.setItem('re-config', JSON.stringify(data))
        data.source = 'online'
        console.log('Loading config online')
    } catch (e) {
        data = JSON.parse(localStorage.getItem('re-config') || 'null')
        if (data) {
            data.source = 'offline'
            console.log('Error while fetching config, loading cached version')
        } else {
            console.log('Error while fetching config, no cached version found')
            throw e
        }
    } finally {
        Object.assign(defaultConfig, data)
    }
    return defaultConfig
}
