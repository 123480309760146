var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{ level: _vm.orientation == 'horizontal' },attrs:{"aria-label":_vm.item.name}},[_c('div',{staticClass:"has-text-left is-size-7"},[_vm._v(" "+_vm._s(_vm.$t(`${_vm.item.location}.${_vm.item.name}`))+" ")]),(_vm.item.type === 'custom_parcel')?_c('div',{staticClass:"has-text-weight-bold has-text-black is-size-7",class:{
            'has-text-right': _vm.orientation === 'horizontal',
            'has-text-left': _vm.orientation === 'vertical',
        }},_vm._l((_vm.get_value_from_store('features', 'parcel_ids')),function(value,index){return _c('p',{key:index,staticClass:"mb-0 paragraph"},[_vm._v(" "+_vm._s(_vm.get_value_text(_vm.item, index))+" ")])}),0):(_vm.item.type === 'custom_garage')?_c('div',{staticClass:"has-text-weight-bold has-text-black is-size-7",class:{
            'has-text-right': _vm.orientation === 'horizontal',
            'has-text-left': _vm.orientation === 'vertical',
        }},_vm._l((_vm.get_value_from_store(_vm.item.location, _vm.item.name)),function(value,index){return _c('p',{key:index,staticClass:"mb-0 paragraph"},[_vm._v(" "+_vm._s(_vm.get_value_text(_vm.item, index))+" ")])}),0):_c('div',{staticClass:"has-text-weight-bold is-size-7",class:{
            'has-text-black': !_vm.get_value_text(_vm.item).includes('null'),
            'has-text-danger': _vm.get_value_text(_vm.item).includes('null'),
            'has-text-right': _vm.orientation === 'horizontal',
            'has-text-left': _vm.orientation === 'vertical',
        }},[_vm._v(" "+_vm._s(_vm.get_value_text(_vm.item))+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }