var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.center.lat != null)?_c('l-map',{ref:"map",staticStyle:{"height":"100%","width":"100%"},attrs:{"zoom":_vm.getZoomLevel,"max-zoom":20,"min-zoom":12,"center":_vm.center,"options":_vm.options}},[(_vm.marker)?_c('l-marker',{attrs:{"lat-lng":[_vm.center.lat, _vm.center.lng]}}):_vm._e(),(_vm.layersControlVisible)?_c('l-control-layers',{attrs:{"collapsed":false,"sort-layers":true}}):_vm._e(),_c('l-tile-layer',{attrs:{"visible":_vm.aerialTileVisible,"url":_vm.aerialTileUrl,"options":_vm.tile_options,"name":_vm.$t('global.map.aerial_view'),"layer-type":"base"}}),_c('l-tile-layer',{attrs:{"visible":_vm.cadastralTileVisible,"url":_vm.cadastralTileUrl,"options":{ maxZoom: 21, maxNativeZoom: 19 },"name":_vm.$t('global.map.cadastral_view'),"layer-type":"base"}}),(
            _vm.validGeoJson(_vm.flood_regular_low_geojson) &&
                !_vm.$config.HIDDEN_FLOOD_TYPES.includes('regular')
        )?_c('l-geo-json',{attrs:{"visible":_vm.floodVisible,"geojson":_vm.flood_regular_low_geojson,"options-style":_vm.style_flood,"name":_vm.$t('global.map.flood_zone_low'),"layer-type":"overlay"}}):_vm._e(),(
            _vm.validGeoJson(_vm.flood_regular_medium_geojson) &&
                !_vm.$config.HIDDEN_FLOOD_TYPES.includes('regular')
        )?_c('l-geo-json',{attrs:{"visible":_vm.floodVisible,"geojson":_vm.flood_regular_medium_geojson,"options-style":_vm.style_flood,"name":_vm.$t('global.map.flood_zone_medium'),"layer-type":"overlay"}}):_vm._e(),(
            _vm.validGeoJson(_vm.flood_regular_high_geojson) &&
                !_vm.$config.HIDDEN_FLOOD_TYPES.includes('regular')
        )?_c('l-geo-json',{attrs:{"visible":_vm.floodVisible,"geojson":_vm.flood_regular_high_geojson,"options-style":_vm.style_flood,"name":_vm.$t('global.map.flood_zone_high'),"layer-type":"overlay"}}):_vm._e(),(
            _vm.validGeoJson(_vm.flood_insurance_exclusion_geojson) &&
                !_vm.$config.HIDDEN_FLOOD_TYPES.includes('insurance_exclusion')
        )?_c('l-geo-json',{attrs:{"visible":_vm.floodVisible,"geojson":_vm.flood_insurance_exclusion_geojson,"options-style":_vm.style_flood,"name":_vm.$t('global.map.exclusion_flood_zone'),"layer-type":"overlay"}}):_vm._e(),(_vm.map_data_ready && _vm.validGeoJson(_vm.parcel_geojson))?_c('l-geo-json',{ref:"parcel_features",attrs:{"geojson":_vm.parcel_geojson,"options":_vm.parcel_options}}):_vm._e(),(_vm.map_data_ready && _vm.validGeoJson(_vm.building_geojson))?_c('l-geo-json',{ref:"building_features",attrs:{"geojson":_vm.building_geojson,"options":_vm.building_options}}):_vm._e(),(_vm.map_data_ready && _vm.size !== 'small' && _vm.validGeoJson(_vm.address_geojson))?_c('l-geo-json',{attrs:{"geojson":_vm.address_geojson,"options":_vm.address_options}}):_vm._e(),(_vm.validGeoJson(_vm.swimming_pools_geojson))?_c('l-geo-json',{attrs:{"geojson":_vm.swimming_pools_geojson,"options-style":_vm.style_swimming_pools,"name":_vm.$t('global.map.swimming_pools'),"layer-type":"overlay"}}):_vm._e(),(_vm.showStreetNames)?_c('l-tile-layer',{attrs:{"url":_vm.streetnameTileUrl,"options":{ maxZoom: 21, maxNativeZoom: 19 },"layer-type":"overlay","name":_vm.$t('global.map.street_name')}}):_vm._e(),_vm._t("custom-layer")],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }