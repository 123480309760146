<template>
    <div class="report-footer px-5 pb-5">
        <div class="columns">
            <div class="column is-11 has-text-left">
                <slot></slot>
                <url-text :locale-key="'footer.text'"></url-text>
            </div>
            <div class="column is-1 has-text-right">{{ pageNumber || '' }}</div>
        </div>
    </div>
</template>

<script>
import UrlText from './UrlText.vue'

export default {
    components: {
        UrlText,
    },
    props: {
        pageNumber: {
            type: Number,
            default: null,
        },
    },
}
</script>
