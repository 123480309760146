<template>
    <div class="py-2">
        <div v-if="items.length || !filterNull" :aria-label="`section-${name}`">
            <div
                class="section-header level has-text-weight-bold has-text-black has-text-left is-size-6"
            >
                <div class="level-left icon-text">
                    <img
                        v-if="name !== 'warnings' && icon"
                        class="icon is-medium mr-2"
                        :src="`${publicPath}/${icon}.svg`"
                    />
                    <span
                        v-if="name === 'warnings'"
                        class="icon is-medium mr-2 has-text-warning"
                    >
                        <i class="mdi mdi-alert mdi-24px"></i>
                    </span>

                    <span>{{ sectionData.name ?? $t('section.' + name) }}</span>
                </div>
            </div>
            <div v-if="orientation === 'vertical'">
                <div v-if="columns === 1">
                    <section-item
                        v-for="item in items"
                        :key="item.name"
                        :item="item"
                        :orientation="'horizontal'"
                        :section-data="sectionData"
                    ></section-item>
                </div>
                <div v-if="columns === 2">
                    <div class="columns">
                        <div class="column is-6">
                            <section-item
                                v-for="item in items_half_1"
                                :key="item.name"
                                :item="item"
                                :orientation="'horizontal'"
                                :section-data="sectionData"
                            ></section-item>
                        </div>
                        <div class="column is-6">
                            <section-item
                                v-for="item in items_half_2"
                                :key="item.name"
                                :item="item"
                                :orientation="'horizontal'"
                                :section-data="sectionData"
                            ></section-item>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="section-grid">
                    <section-item
                        v-for="item in items"
                        :key="item.name"
                        class="section-grid-item"
                        :orientation="'vertical'"
                        :item="item"
                        :section-data="sectionData"
                    ></section-item>
                </div>
            </div>
            <div v-if="relevantPictures.length" class="columns is-flex-wrap-wrap">
                <div v-for="pic of relevantPictures" :key="pic.id" class="column is-3">
                    <div class="image my-2">
                        <img :src="documentURL(pic.document_ref)" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { config } from '@/shared/assets/config/config'

import SectionItem from '@/shared/components/SectionItem.vue'
import utils from '@/shared/plugins/utils'
import { mapGetters } from 'vuex'

export default {
    name: 'Section',
    components: {
        SectionItem,
    },
    props: {
        name: { type: String, default: 'general_info' },
        columns: { type: Number, default: 1 },
        orientation: { type: String, default: 'vertical' },
        filterBuildingType: { type: String, default: null },
        filterNull: { type: Boolean, default: true },
        sectionData: {
            type: Object,
            default: () => {
                return {}
            },
        },
    },
    data() {
        return {
            publicPath: this.$config.FRONTEND_URL.trim().replace(/\/$/, ''),
        }
    },
    computed: {
        ...mapGetters([
            'getFeatures',
            'getValuation',
            'getDVMFeatures',
            'getOVMFeatures',
            'getDocuments',
            'getValuationType',
        ]),
        items() {
            let filtered_config = config.filter(
                (item) =>
                    item.sections.includes(this.name) &&
                    !this.$config.HIDDEN_FEATURES.includes(item.name)
            )
            if (this.filterBuildingType) {
                filtered_config = filtered_config.filter((item) =>
                    item.building_type_scope.includes(this.filterBuildingType)
                )
            }
            // Adapted from https://stackoverflow.com/a/45322101/16901626
            const resolve = function(path, obj) {
                return path.split('.').reduce(function(prev, curr) {
                    return prev ? prev[curr] ?? null : null
                }, obj || self)
            }
            if (this.filterNull) {
                // TODO: consider deduplicating this with the SectionItem.vue version
                // See https://github.com/rockestate/rockestate_vue/pull/781#discussion_r1414051021
                const search_dict = {
                    features: this.getFeatures,
                    dvm_features: this.getDVMFeatures,
                    ovm_features: this.getOVMFeatures,
                    valuation: this.getValuation,
                    section_data: this.sectionData,
                }
                filtered_config = filtered_config.filter((item) => {
                    const value = resolve(`${item.location}.${item.name}`, search_dict)
                    if (value === null) {
                        return false
                    } else if (item.type === 'multi_enum_open') {
                        return value.length
                    } else if (item.location === 'valuation') {
                        return value !== 0
                    } else {
                        return true
                    }
                })
            }
            return filtered_config
        },
        icon() {
            let section_item = config.filter(
                (item) => (item.location === 'section') & (item.name === this.name)
            )[0]
            if (section_item.icon) {
                return section_item.icon
            } else {
                return null
            }
        },
        items_half_1() {
            let items = this.items
            let half = Math.ceil(items.length / 2)
            return items.slice(0, half)
        },
        items_half_2() {
            let items = this.items
            let half = Math.floor(items.length / 2)
            if (items.length === 1) {
                return []
            } else {
                return items.slice(-half)
            }
        },
        relevantPictures() {
            let docs =
                this.getValuationType === 'ovm'
                    ? this.getDocuments.filter((d) =>
                          ['image/png', 'image/jpeg'].includes(d.content_type)
                      )
                    : []
            if (this.name === 'parcels') {
                docs = docs.filter(
                    (d) =>
                        d.purpose.includes('exterior') ||
                        d.purpose.includes('cadastral') ||
                        d.purpose.includes('parcel') ||
                        d.purpose.includes('annex')
                )
            } else if (this.name === 'room') {
                docs = docs.filter(
                    (d) =>
                        d.purpose === 'interior_picture' &&
                        d.room_id === this.sectionData.room.id
                )
            } else if (this.name === 'interior') {
                docs = docs.filter((d) => d.purpose.includes('interior') && !d.room_id)
            } else {
                docs = []
            }
            return docs
        },
    },
    methods: {
        documentURL(document_ref) {
            return (
                utils.urlJoin(process.env.VUE_APP_API_URL || '', [
                    this.$config.VALUATION_API_URL,
                    'document',
                    document_ref,
                ]) + '?view=true&variant=thumbnail'
            )
        },
    },
}
</script>

<style lang="scss">
.section-header,
.level:not(:last-child) {
    padding-bottom: 0.5rem !important;
    margin-bottom: 0.5rem !important;
    border-width: 0 0 1px 0;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.1);
}
.section-header {
    margin-bottom: 1rem !important;
}
.section-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    grid-gap: 2px;
}
.section-grid-item {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
</style>

<style lang="scss" scoped>
img {
    object-fit: contain;
}
</style>
