<template>
    <div>
        <div id="toc" class="card">
            <div class="card-content content is-small has-text-centered">
                <div class="toc_actions">
                    <b-button
                        size="is-normal"
                        type="is-primary"
                        icon-left="close"
                        tag="router-link"
                        :to="{
                            name: 'action',
                            params: {
                                action: '2d',
                                lang: $route.params.lang,
                            },
                            query: $route.query,
                        }"
                    />
                    <b-button
                        size="is-normal"
                        type="is-primary"
                        icon-left="download"
                        tag="a"
                        :href="reportURL"
                    />
                </div>
            </div>
        </div>
        <div id="report-vue" class="has-text-centered is-size-7">
            <div id="page-general" class="page is-flex is-flex-direction-column">
                <div class="section content pt-5 px-5 pb-2">
                    <div class="columns is-multiline has-text-left">
                        <div class="column is-6 has-text-left pb-0">
                            <img
                                width="150px"
                                src="@/shared/assets/icons/rockestate-logo-horizontal-dark.svg"
                            />
                        </div>
                        <div class="column is-6 has-text-right is-size-6 pb-0">
                            <div class="has-text-weight-bold has-text-black">
                                {{ $t('front.core_title') }}
                            </div>
                        </div>
                        <div class="column is-6 py-2">
                            <p class="is-size-7 mb-0">
                                {{ $t('address.full_address') }}
                            </p>
                            <p class="has-text-weight-bold has-text-black is-size-7">
                                {{ getAddress.full_address }}
                            </p>
                        </div>
                        <div class="column is-3 py-2">
                            <p class="is-size-7 mb-0">
                                {{ $t('export.by') }}
                            </p>
                            <p class="has-text-weight-bold has-text-black is-size-7">
                                {{ $route.query.email || getFullStore.auth.email }}
                            </p>
                        </div>
                        <div class="column is-3 py-2">
                            <p class="is-size-7 mb-0">{{ $t('export.date') }}</p>
                            <p class="has-text-weight-bold has-text-black is-size-7">
                                {{
                                    new Intl.DateTimeFormat('fr-BE', {
                                        year: 'numeric',
                                        month: 'numeric',
                                        day: 'numeric',
                                        hour: 'numeric',
                                        minute: 'numeric',
                                        /*second: 'numeric',*/
                                        timeZone: 'Europe/Brussels',
                                    }).format(new Date())
                                }}
                            </p>
                        </div>
                    </div>
                    <div class="columns is-multiline">
                        <div class="column is-4">
                            <div class="image is-square mb-2">
                                <GmapStreetViewPanorama
                                    v-if="getView.gsv_p"
                                    :style="
                                        `width: 100%; height: 100%; ${
                                            isStatic ? 'pointer-events: none;' : ''
                                        }`
                                    "
                                    class="pano has-ratio"
                                    :pov="{
                                        heading: getView.gsv_ph,
                                        pitch: getView.gsv_pp,
                                    }"
                                    :zoom="getView.gsv_pz"
                                    :pano="getView.gsv_p"
                                    :options="{
                                        disableDefaultUI: true,
                                        fullscreenControl: false,
                                        addressControl: false,
                                        scrollwheel: false,
                                        panningGesturesEnabled: !isStatic,
                                        clickToGo: false,
                                    }"
                                ></GmapStreetViewPanorama>
                            </div>
                            <div
                                class="image is-square my-2"
                                :style="isStatic ? 'pointer-events: none;' : ''"
                            >
                                <re-map
                                    :building-id="getFeatures.building_id"
                                    :parcel-ids="getFeatures.parcel_ids"
                                    :auto-select-parcels="true"
                                    :level="getFeatures.level"
                                    :map-scene2d="getFullValuationStore.map_scene2d"
                                    :center="{
                                        lat: getFeatures.f_lat,
                                        lng: getFeatures.f_lng,
                                    }"
                                    :read-only="true"
                                    focus-layer="building"
                                    class="has-ratio"
                                    size="small"
                                    :default-zoom-modifier="1"
                                    :layers-control-visible="false"
                                    :region="getFeatures.region"
                                    :flood-visible="false"
                                    :options="{
                                        zoomControl: false,
                                        attributionControl: false,
                                        scrollWheelZoom: false,
                                        dragging: !isStatic,
                                    }"
                                    :lang="$route.params.lang"
                                    @update:parcelIds="
                                        UPDATE_FEATURES({ parcel_ids: $event })
                                    "
                                    @update:mapScene2d="UPDATE_MAP_SCENE2D($event)"
                                ></re-map>
                            </div>
                            <div
                                class="image is-square my-2"
                                :style="isStatic ? 'pointer-events: none;' : ''"
                            >
                                <re-map
                                    :building-id="getFeatures.building_id"
                                    :parcel-ids="getFeatures.parcel_ids"
                                    :auto-select-parcels="false"
                                    :level="getFeatures.level"
                                    :region="getFeatures.region"
                                    :map-scene2d="getFullValuationStore.map_scene2d"
                                    :center="{
                                        lat: getFeatures.f_lat,
                                        lng: getFeatures.f_lng,
                                    }"
                                    :read-only="true"
                                    focus-layer="building"
                                    class="has-ratio"
                                    size="medium"
                                    :default-zoom-modifier="-1"
                                    :layers-control-visible="false"
                                    :options="{
                                        zoomControl: false,
                                        attributionControl: false,
                                        scrollWheelZoom: false,
                                        dragging: !isStatic,
                                    }"
                                    :aerial-tile-visible="false"
                                    :cadastral-tile-visible="false"
                                ></re-map>
                            </div>
                            <div class="image is-3by2 mt-2">
                                <renderer
                                    v-if="getFeatures.level === '3'"
                                    class="has-ratio"
                                    :building-id="getFeatures.building_id"
                                    :show-attributions="false"
                                    :auto-rotate="!isStatic"
                                    :map-scene3d="mapScene3d"
                                    @update:mapScene3d="mapScene3d = $event"
                                ></renderer>
                                <div v-else class="card has-ratio">
                                    <div class="card-content">
                                        <div class="content">
                                            {{ $t('3d model unavailable') }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="column is-4">
                            <report-section :name="'address_location'"></report-section>
                            <report-section :name="'c_building'"></report-section>
                            <report-section
                                :name="'secondary_buildings'"
                            ></report-section>
                            <report-section :name="'flood'"></report-section>
                        </div>
                        <div class="column is-4">
                            <report-section :name="'c_parcels'"></report-section>
                            <report-section
                                :name="'reconstruction_value'"
                            ></report-section>
                            <report-section :name="'economic_activity'"></report-section>
                            <report-section :name="'roof'"></report-section>
                            <!--<report-section :name="'warnings'"></report-section>-->
                        </div>
                    </div>
                </div>
                <report-footer></report-footer>
            </div>
        </div>
    </div>
</template>

<script>
import utils from '@/shared/plugins/utils'

import ReMap from '../Map.vue'
import ReportFooter from './Footer.vue'
import ReportSection from './Section.vue'
import Renderer from '@/shared/components/3DComponents/Renderer3D.vue'

import { mapGetters, mapMutations } from 'vuex'

export default {
    name: 'Report',
    components: {
        ReMap,
        ReportFooter,
        ReportSection,
        Renderer,
    },
    data() {
        return {
            mapScene3d: {
                building_id: null,
            },
        }
    },
    computed: {
        ...mapGetters([
            'getFullStore',
            'getFullValuationStore',
            'getFeatures',
            'getAddress',
            'getView',
        ]),
        reportURL() {
            let query_string = new URLSearchParams({
                ...this.$route.query,
                lang: this.$route.params.lang,
            }).toString()

            return utils.urlJoin(
                process.env.VUE_APP_API_URL || '',
                `/report?${query_string}`
            )
        },
        isStatic() {
            return this.$route.query.static === 'true'
        },
    },
    methods: {
        ...mapMutations(['UPDATE_FEATURES', 'UPDATE_MAP_SCENE2D']),
    },
}
</script>

<style lang="scss">
#renderer-3d {
    height: 150px;
    width: 100%;
}
#toc {
    top: 5.5rem;
    left: 10px;
    position: fixed;
    z-index: 0;
}
.toc_actions {
    display: flex;
    gap: 0.25rem;
}
.page {
    z-index: 1;
}
@page {
    size: A4;
}
body {
    background: white;
}
.page {
    width: 100%;
    height: 100%;
    padding: 0cm;
    margin: 1cm auto;
    border: 1px #d3d3d3 solid;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}
@media screen and (min-width: 769px) {
    .page {
        width: 794px;
        height: 1122px;
    }
}
@media print {
    body.has-navbar-fixed-top {
        padding-top: 0;
        margin-top: 0;
    }
    #navbar,
    #toc {
        display: none;
    }
    .page {
        width: 794px;
        height: 1122px;
        padding: 0;
        margin: 0;
        border: 0;
        box-shadow: none;
        page-break-after: always;
    }
}
.report-footer {
    margin-top: auto;
    padding-top: auto;
    background-color: transparent;
}
.mt-10 {
    margin-top: 5rem;
}
.section-header,
.level:not(:last-child) {
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;
    border-width: 0 0 1px 0;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.1);
}
.section-header {
    margin-bottom: 1rem;
    margin-bottom: 1rem;
}
.chart1 {
    max-width: 940px;
    width: 100%;
    height: 150px;
    margin-bottom: 50px;
    padding-top: 45px;
    &.chart2 {
        max-width: 820px;
        transform: scale(1.3) translateY(-90px);
        margin-top: 50px;
        margin-bottom: 0px;
    }
}
.map {
    width: 100%;
    height: 600px;
}
.leaflet-transparent {
    background-color: rgba(255, 0, 0, 0);
}
</style>
